<!--课程协议详情-->
<template>
    <div class="app-page-2">
        <div class="container">
            <div class="mineContainer">
                <div class="protocolContain">
                    <div class="title">
                        <h3>{{protocolName}}</h3>
                    </div>
                    <div class="protocol_list">
                        <ul>
                            <li v-for="(item,index) in protocolImgs" :key='index'>
                                <img :src="item">
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { getProtocolList} from '@/api/api'
export default {
    name:'course',
    components:{
       
    },
    middleware: 'accountVerification',
    data(){
        return{
            id: null,
            page: 1,
            limit: 1000,
            protocolList:[],
            protocolName:null,
            protocolImgs:[],
        }
    },
    mounted(){
        let id = this.$route.query.id
        let type = 2
        this.id = id
      this.getProtocolList()
    },
    methods:{
      // 获取协议
      async getProtocolList() {
        const params = {
            page: this.page,
            limit: this.limit
        }
        let response = await getProtocolList(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.protocolList = response.data
        let protocolList = response.data
        for(const item of protocolList) {
            if(item.id === this.id) {
                this.protocolName = item.protocolName
                this.protocolImgs = item.protocolImg.split(',')
            }
        }
      },

    },
}
</script>
<style lang="less" scoped >
.mineContainer{
    width:100%;
    overflow: hidden;
    background:#fff;
    margin:0 auto 30px auto;
    border: 1px solid #DBDBDB;
    display: flex;
}
.protocolContain{
    width:100%;
    padding:0 3%;
    overflow: hidden;
    background:#fff;
    .title h3{
        font-size: 22px;
        color:#333333;
        text-align: left;
        font-weight: normal;
        line-height: 0.8rem;
        height: 0.8rem;
    }
    .protocol_list{
        ul{
            margin:0;
            padding:0;
            li{
                width:100%;
                img{
                    max-width:100%;
                }
            }
                
        } 
    }
    
    
    
}
</style>
